// IMPORTS
import { subNav } from "./_subNav.js";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import { footer } from "./footer.js";
// COMPONENTS
fetch("./_topNav.html")
  .then((response) => response.text())
  .then((html) => {
    document.getElementById("topNav").innerHTML = html;
  })
  .catch((error) => {
    console.error("Error fetching topnav:", error);
  });
fetch("./_subNav.html")
  .then((response) => response.text())
  .then((html) => {
    document.getElementById("subNav").innerHTML = html;
    subNav();
  })
  .catch((error) => {
    console.error("Error fetching subnav", error);
  });
fetch("./_footer.html")
  .then((response) => response.text())
  .then((html) => {
    document.getElementById("footer").innerHTML = html;
    footer();
  })
  .catch((error) => {
    console.error("Error fetching footer", error);
  });

// PARTNERS FUNCTION
const copy = document.querySelector(".partner__list__slide").cloneNode(true);
document.querySelector(".partner__list").append(copy);

// FETCHING FOR NEWS SECTION
const strapiURL =
  "https://strapi-cfe.onrender.com/api/tintucs?populate=*&pagination[pageSize]=7&sort=date:desc";
fetch(strapiURL)
  .then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Error: " + response.status);
    }
  })
  .then((response) => {
    const newsList = document.querySelector(".news__list");
    newsList.innerHTML = "";
    const news = response.data;
    const limitedData = response.data.slice(0, 7);
    limitedData.forEach((item) => {
      const id = item.id;
      const title = item.attributes.Tuade;
      const date = item.attributes.date;
      const img_1 = item.attributes.anh_1.data[0].attributes.url;
      let text_1 = item.attributes.doanvan1;
      text_1 = text_1.replace(/<br\s*\/?>/gi, "").trim();
      if (limitedData.indexOf(item) < 4) {
        const newsItem = `
          <a href="./news_detailed.html?id=${id}" class="news__list__item">
            <div class="news__list__item__img-container">
              <img
              src="${img_1}"
              alt="Ảnh tin tức clb"
              class="news__list__item__img"
              />
            </div>
            <div class="news__list__item__text">
              <div class="news__list__item__text__title">
                <h6 class="news__list__item__text__title__new-title">
                  ${title}
                </h6>
                <p class="news__list__item__text__title__date">
                  ${date}
                </p>
              </div>
              <div class="news__list__item__text__description">
                ${text_1}
              </div>
            </div>
          </a>
          `;
        newsList.insertAdjacentHTML("beforeend", newsItem);
      }
    });
  });

// FETCHING FOR NEWS SECTION
document.addEventListener("DOMContentLoaded", function () {
  const videosURL =
    "https://strapi-cfe.onrender.com/api/videos?populate=*&pagination[pageSize]=7";
  fetch(videosURL)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Error: " + response.status);
      }
    })
    .then((response) => {
      console.log(response);
      const pagination = document.querySelector(".carousel-indicators");
      const carousel = document.querySelector(".hero__carousel__list");
      const newsList = document.querySelector(".news__list");
      carousel.innerHTML = "";
      pagination.innerHTML = "";
      newsList.innerHTML = "";
      const videos = response.data;
      const limitedData = response.data.slice(0, 7);
      limitedData.forEach((item) => {
        const id = item.id;
        const videoUrl = item.attributes.Video.data[0].attributes.url;
        const title = item.attributes.Title;
        if (limitedData.indexOf(item) === 0) {
          const newCarouselItem = `
            <div class="carousel-item hero__carousel__list__item active">
              <video src=${videoUrl} autoplay muted loop class="d-block w-100 hero__carousel__list__item__img" alt="..."></video>
              <div class="carousel-caption top-0 hero__carousel__list__item__text-box">
                <h2>${title}</h2>
              </div>
            </div>
            `;

          // removed part above: <a href="./news_detailed.html?id=${id}">Đọc thêm &rarr;</a>

          carousel.insertAdjacentHTML("beforeend", newCarouselItem);
          const newPagination = `
            <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="0" aria-label="Slide 1" class="active" aria-current="true"></button>
            `;
          pagination.insertAdjacentHTML("beforeend", newPagination);
        } else {
          const newCarouselItem = `
            <div class="carousel-item hero__carousel__list__item">
              <video src=${videoUrl} autoplay muted loop class="d-block w-100 hero__carousel__list__item__img" alt="..."></video>
              <div class="carousel-caption top-0 hero__carousel__list__item__text-box">
                <h2>${title}</h2>
              </div>
            </div>
            `;
          carousel.insertAdjacentHTML("beforeend", newCarouselItem);
          const newPagination = `
            <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="${
              pagination.children.length
            }" aria-label="Slide ${pagination.children.length + 1}"></button>
            `;
          pagination.insertAdjacentHTML("beforeend", newPagination);
        }
      });
    })
    .catch((error) => {
      console.error("Error:", error);
    });
});

// LINKING TO NEWS PAGE
const cta = document.querySelector(".news__read-more");
cta.href = "/news.html?page=1";

// FETCHING FOR INTRODUCTION SECTION
const introURL =
  "https://strapi-cfe.onrender.com/api/trang-chu-introductions?populate=*&sort=ViTri:asc";
fetch(introURL)
  .then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Error: " + response.status);
    }
  })
  .then((response) => {
    const arr = response.data;
    const introContainer = document.querySelector(".strapi-content-container");
    introContainer.innerHTML = "";
    arr.forEach((item) => {
      const heading = item?.attributes?.tieu_de ?? null;
      const text1 = item?.attributes?.doan_van_1 ?? null;
      const text2 = item?.attributes?.doan_van_2 ?? "";
      const img1 = item?.attributes?.anh_1?.data?.attributes?.url ?? null;
      const img2 = item?.attributes?.anh_2?.data?.attributes?.url ?? null;
      const img3 = item?.attributes?.anh_3?.data?.attributes?.url ?? null;
      let numOfImg;
      if (img1 != null && img2 != null && img3 != null) {
        numOfImg = 3;
      } else if (img1 != null && img2 != null && img3 == null) {
        numOfImg = 2;
      } else if (img1 != null && img2 == null && img3 == null) {
        numOfImg = 1;
      }
      let addIntroduction = ``;
      if ((arr.indexOf(item) + 1) % 2 != 0) {
        addIntroduction = `
        <section class="introduction1 img-${numOfImg}">
          <div class="introduction1__text">
            <h4 class="introduction1__text__title">${heading}</h4>
            <div class="introduction1__text__content">
              ${text1 != null ? `<p>${text1}</p>` : null}
              ${text2 != null ? `<p>${text2}</p>` : null}
            </div>
          </div>
          <div class="introduction1__grid-box">
            ${
              img1 != null
                ? `<img class="introduction1__grid-box__img1 img" src="${img1}"/>`
                : ""
            }
            ${
              img2 != null
                ? `<img class="introduction1__grid-box__img2 img" src="${img2}"/>`
                : ""
            }
            ${
              img3 != null
                ? `<img class="introduction1__grid-box__img3 img" src="${img3}"/>`
                : ""
            }
          </div>
        </section>
        `;
        introContainer.insertAdjacentHTML("beforeend", addIntroduction);
      } else {
        addIntroduction = `
        <section class="introduction2">
          <div class="introduction2__title">
            <h4>${heading}</h4>
            <hr />
          </div>
          <div class="introduction2__content">
            <div class="introduction2__content__img-container img-${numOfImg}">
              ${img1 != null ? `<img src="${img1}" class="img1" />` : ""}
              ${img2 != null ? `<img src="${img2}" class="img2" />` : ""}
              ${img3 != null ? `<img src="${img3}" class="img3" />` : ""}
            </div>
            <div class="introduction2__content__text">
              ${text1 != null ? `<p>${text1}</p>` : null}
              ${text2 != null ? `<p>${text2}</p>` : null}
            </div>
          </div>
        </section>
        `;
        introContainer.insertAdjacentHTML("beforeend", addIntroduction);
      }
    });
  });

// FETCHING FOR Q&A SECTION
let addedQuestion = ``;
const questionURL = "https://strapi-cfe.onrender.com/api/qn-as?populate=*";
fetch(questionURL)
  .then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Error: " + response.status);
    }
  })
  .then((response) => {
    const questionList = document.querySelector(".faqs__question-list");
    questionList.innerHTML = "";
    const items = response.data;
    items.forEach((item) => {
      const question = item.attributes.CauHoi;
      const answer = item.attributes.CauTraLoi;
      addedQuestion = `
      <div class="faqs__question-list__item">
        <div class="faqs__question-list__item__question">
          <h4>${question}</h4>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="35" height="35" fill="none" stroke="#180f4d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="faqs__question-list__item__question__caret-down">
              <path d="M6 9l6 6 6-6"/>
          </svg>
        </div>
        <p class="faqs__question-list__item__answer">
          ${answer}
        </p>
      </div>
      `;
      questionList.insertAdjacentHTML("beforeend", addedQuestion);
    });
    // ACCORDION
    const accordion_items = document.querySelectorAll(
      ".faqs__question-list__item"
    );
    accordion_items.forEach((item) => {
      item.addEventListener("click", () => {
        item.classList.toggle("active");
      });
    });
  });
