export function footer() {
  let apiUrl = "https://strapi-cfe.onrender.com/api/footers";
  fetch(apiUrl)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Error: " + response.status);
      }
    })
    .then((response) => {
      const data = response.data; // Access the 'data' property
      let footerItems = "";
      data.forEach((item) => {
        const content = document.querySelector(".footer__contacts__list");
        const attributes = item.attributes;
        const address = attributes.address;
        const sdt = attributes.sdt;
        const email = attributes.email;
        const facebook = attributes.facebook;
        let footer = `
          <li class="footer__contacts__list__item facebook">
            <a target="_blank" href=${facebook} class="media media--facebook">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
              </svg>
              <h6 class="text">${facebook}</h6>
            </a>
          </li>
          <li class="footer__contacts__list__item location">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
          <path d="M14.0002 13.9163C13.2266 13.9163 12.4847 13.6091 11.9378 13.0621C11.3908 12.5151 11.0835 11.7732 11.0835 10.9997C11.0835 10.2261 11.3908 9.48426 11.9378 8.93728C12.4847 8.3903 13.2266 8.08301 14.0002 8.08301C14.7737 8.08301 15.5156 8.3903 16.0626 8.93728C16.6095 9.48426 16.9168 10.2261 16.9168 10.9997C16.9168 11.3827 16.8414 11.762 16.6948 12.1158C16.5482 12.4697 16.3334 12.7912 16.0626 13.0621C15.7917 13.3329 15.4702 13.5477 15.1163 13.6943C14.7625 13.8409 14.3832 13.9163 14.0002 13.9163ZM14.0002 2.83301C11.8342 2.83301 9.757 3.69342 8.22546 5.22497C6.69391 6.75652 5.8335 8.83374 5.8335 10.9997C5.8335 17.1247 14.0002 26.1663 14.0002 26.1663C14.0002 26.1663 22.1668 17.1247 22.1668 10.9997C22.1668 8.83374 21.3064 6.75652 19.7749 5.22497C18.2433 3.69342 16.1661 2.83301 14.0002 2.83301Z" fill="white"/>
          </svg>
            <h6 class="text">${address}</h6>
          <li class="footer__contacts__list__item phone">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
<path d="M19.3152 15.5567L18.7844 16.0852C18.7844 16.0852 17.5209 17.3405 14.0734 13.9128C10.6259 10.4852 11.8894 9.22982 11.8894 9.22982L12.223 8.89615C13.0479 8.07715 13.126 6.76115 12.4062 5.79982L10.9362 3.83632C10.0449 2.64632 8.32403 2.48882 7.30319 3.50382L5.47153 5.32382C4.96636 5.82782 4.62803 6.47882 4.66886 7.20215C4.77386 9.05365 5.61153 13.0355 10.2829 17.6812C15.2377 22.6068 19.8869 22.8028 21.7874 22.6255C22.3894 22.5695 22.912 22.2638 23.3332 21.8438L24.9899 20.1965C26.1099 19.0847 25.7949 17.1772 24.3622 16.399L22.1339 15.1868C21.1935 14.677 20.0502 14.8263 19.3152 15.5567Z" fill="white"/>
</svg>
            <h6 class="text">${sdt}</h6>
          </li>
          <li class="footer__contacts__list__item email">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
<path d="M4.66683 23.8337C4.02516 23.8337 3.47605 23.6054 3.0195 23.1488C2.56294 22.6923 2.33427 22.1428 2.3335 21.5003V7.50033C2.3335 6.85866 2.56216 6.30955 3.0195 5.85299C3.47683 5.39644 4.02594 5.16777 4.66683 5.16699H23.3335C23.9752 5.16699 24.5247 5.39566 24.982 5.85299C25.4393 6.31033 25.6676 6.85944 25.6668 7.50033V21.5003C25.6668 22.142 25.4386 22.6915 24.982 23.1488C24.5254 23.6062 23.9759 23.8344 23.3335 23.8337H4.66683ZM14.0002 15.667L23.3335 9.83366V7.50033L14.0002 13.3337L4.66683 7.50033V9.83366L14.0002 15.667Z" fill="white"/>
</svg>
            <h6 class="text">${email}</h6>
          </li>
`;
        footerItems += footer;
        // const content1 = attributes.doanvan1;
        content.innerHTML = footerItems;
      });
    });
}
