export function subNav() {
  // FUNCTION FOR DROPDOWN
  // TRANSFROM CLOSE ICON
  const dropdownBtn = document.querySelector(".subnav__dropdown-btn");
  const searchbox = document.querySelector(".search-box");
  const container = document.querySelector(".subnav__btns-container");
  dropdownBtn.addEventListener("click", () => {
    container.classList.toggle("display");
    searchbox.classList.toggle("display");
    dropdownBtn.classList.toggle("active");
  });
  let searchBar = document.getElementById("search-box-input");
  let searchResult = document.querySelector(".search-result");
  let initialBaos = [];

  searchBar.addEventListener("focus", function () {
    searchResult.classList.add("active");
    const list = document.querySelector(".search-list");
    list.innerHTML = "";

    // Sort initialBaos by date in descending order (most recent first)
    initialBaos.sort((a, b) => new Date(b.date) - new Date(a.date));

    initialBaos.forEach((bao) => {
      const searchItem = document.createElement("li");
      searchItem.classList.add("search-item");
      searchItem.innerHTML = `<a href="news_detailed.html?id=${bao.id}">${bao.title}<br>${bao.date}</a>`;
      list.appendChild(searchItem);
    });
  });

  document.addEventListener("click", function (event) {
    const target = event.target;
    if (!searchResult.contains(target) && target !== searchBar) {
      searchResult.classList.remove("active");
    }
  });

  const apiUrl = "https://strapi-cfe.onrender.com/api/tintucs";
  const apiUrls = [apiUrl];
  let baos = [];

  // Fetch data from multiple APIs
  const fetchData = async () => {
    const promises = apiUrls.map((url) => fetch(url));
    try {
      const responses = await Promise.all(promises);
      const jsonResponses = await Promise.all(
        responses.map((response) => response.json())
      );

      jsonResponses.forEach((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          const data = response.data;
          baos = data.map((bao) => {
            const id = bao.id;
            const attributes = bao.attributes;
            const date = attributes.date;
            const title = attributes.Tuade;

            return {
              id,
              attributes,
              date,
              title,
            };
          });

          // Populate initialBaos with the first 5 items
          initialBaos = baos.slice(0, 5);
        }
      });

      // Sort baos by date in descending order (most recent first)
      baos.sort((a, b) => new Date(b.date) - new Date(a.date));

      // Populate the search list with the filtered and sorted data
      const list = document.querySelector(".search-list");
      list.innerHTML = ""; // Clear previous content

      baos.forEach((bao) => {
        const searchItem = document.createElement("li");
        searchItem.classList.add("search-item");
        searchItem.innerHTML = `<a href="news_detailed.html?id=${bao.id}">${bao.title}<br>${bao.date}</a>`;
        list.appendChild(searchItem);
      });

      // Hide the loading element
      const loading = document.querySelector(".news_item_loading");
      if (loading) {
        loading.style.display = "none";
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch data from multiple APIs
  fetchData();

  searchBar.addEventListener("input", (e) => {
    const value = e.target.value.toLowerCase();
    search(value);
  });

  function search(query) {
    const filteredBaos = baos.filter((bao) => {
      const title = bao.title.toLowerCase();
      const date = formatDate(bao.date).toLowerCase();
      return title.includes(query) || date.includes(query);
    });

    const sortedFilteredBaos = filteredBaos.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    const list = document.querySelector(".search-list");
    list.innerHTML = "";

    // If the search query is empty, show a maximum of 5 items
    if (query === "") {
      initialBaos.forEach((bao) => {
        const searchItem = document.createElement("li");
        searchItem.classList.add("search-item");
        searchItem.innerHTML = `<a href="news_detailed.html?id=${bao.id}">${
          bao.title
        }<br>${formatDate(bao.date)}</a>`;
        list.appendChild(searchItem);
      });
    } else {
      // If there's a search query, show all relevant results
      sortedFilteredBaos.forEach((bao) => {
        const searchItem = document.createElement("li");
        searchItem.classList.add("search-item");
        searchItem.innerHTML = `<a href="news_detailed.html?id=${bao.id}">${
          bao.title
        }<br>${formatDate(bao.date)}</a>`;
        list.appendChild(searchItem);
      });
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return year + "-" + month + "-" + day;
  }

  const newsLink = document.querySelector(".news-link");
  newsLink.href = "/news.html?page=1";
  newsLink.href = "/news.html?page=1";
}
